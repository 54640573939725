import { useEffect } from 'react';
import { useAnalytics } from './useAnalytics';

export const usePageLoadEvent = (name: string) => {
  const { pageLoadedEvent } = useAnalytics();

  useEffect(() => {
    pageLoadedEvent(name);
  }, [name, pageLoadedEvent]);
};
