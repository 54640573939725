import { FC } from 'react';
import { FontsLoader } from './FontsLoader';
import { Segment } from './Segment';

export const AuthRequiredTools: FC = () => (
  <>
    <FontsLoader />
    <Segment />
  </>
);
