import { FC, useEffect } from 'react';
import { useLoadFonts } from '@jux/dui';
import { useGetSupportedFontsQuery } from '@jux/ui/data';

export const FontsLoader: FC = () => {
  const { data: supportedFonts } = useGetSupportedFontsQuery();

  const { loadFonts } = useLoadFonts(supportedFonts);

  useEffect(() => {
    if (!window) return;

    loadFonts();
  }, [loadFonts]);

  return null;
};
