import { FC, useEffect, useState } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useCurrentOrg, useAnalytics } from '@jux/ui/hooks';
import { useUserIdentityState } from '@jux/ui/state';

// this component is used to initialize Segment
export const Segment: FC = () => {
  const { user } = useUser();
  const currentOrganization = useCurrentOrg();
  const { userIdentity, setUserIdentity } = useUserIdentityState();
  const { identifyEvent, groupEvent } = useAnalytics();

  const [hasIdentified, setHasIdentified] = useState(false);

  // check if user is logged in and if so, save their identify
  useEffect(() => {
    if (!user) return;

    // if user don't have some of the information we need, identify them with what we do have
    // that's why we are using 'as string' and not checking if it's undefined
    setUserIdentity({
      userId: (user.sub as string).split('|')[1],
      traits: {
        email: user.email as string,
      },
      org: currentOrganization,
    });
  }, [currentOrganization, setUserIdentity, user]);

  // if user exist identify them
  useEffect(() => {
    if (hasIdentified || !userIdentity) return;

    identifyEvent();
    groupEvent();
    setHasIdentified(true);
  }, [
    groupEvent,
    hasIdentified,
    identifyEvent,
    setHasIdentified,
    userIdentity,
  ]);

  return null;
};
