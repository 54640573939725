import Router from 'next/router';
import { Loading } from '@jux/ui/components';
import { useGetUserOrganizationListQuery } from '@jux/ui/data';
import { Segment } from '@jux/ui/tools/Segment';
import { PATHS } from '../../utils/paths';
import { HOC } from '../HOC.interface';

export const withOnboardingLayout: HOC = (Component) => (props) => {
  // Don't let user into the dashboard unless he has created an organizations
  const organizationsQuery = useGetUserOrganizationListQuery();

  if (organizationsQuery.isLoading) {
    return <Loading />;
  }

  if (organizationsQuery.isError) {
    throw new Error(`Error: ${organizationsQuery.error.message}`);
  }

  if (organizationsQuery.data.length !== 0) {
    Router.push(PATHS.MAIN_PAGE);
    return null;
  }

  return (
    <>
      <Segment />
      <Component {...props} />
    </>
  );
};
