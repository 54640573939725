import { withPageAuthRequired } from '@auth0/nextjs-auth0/client';
import { Loading } from '../../components';
import { HOC } from '../HOC.interface';
import { withPageLoadEvent } from '../withPageLoadEvent';
import { withOrgAuthGuard } from './withOrgAuthGuard';

type AuthOptions = {
  pageName: string;
  returnTo?: string;
  onRedirecting?: () => JSX.Element;
  onError?: (error: Error) => JSX.Element;
};

export const withOrgAuthPageGuard: HOC<AuthOptions> = (
  Component,
  options = {
    pageName: '',
    onRedirecting: () => <Loading />,
    onError: (error) => <div>{error.message}</div>,
  }
) => {
  const PageWithOrganizationAuthGuard = withOrgAuthGuard(
    withPageLoadEvent(
      // We want to make sure that no processes running in the background like uploading assets
      Component,
      {
        name: options.pageName,
      }
    )
  );

  return withPageAuthRequired(PageWithOrganizationAuthGuard, options);
};
